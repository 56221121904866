/* * {
  font-size: 14px;
}

.App {
  font-family: "Poppins";
  font-size: 14px !important;
} */

.recharts-wrapper {
  height: 200px !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
/* * {
  scrollbar-width: auto;
  scrollbar-color: #9d9d9d #d6d6d6;
} */

/* Chrome, Edge, and Safari */
/* *::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #d6d6d6;
}

*::-webkit-scrollbar-thumb {
  background-color: #808080;
  border-radius: 0px;
  border: 0px none #ffffff;
} */
