.customSpinner {
  .ldsRing {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .ldsRing div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #000000;
    border-radius: 50%;
    animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000000 transparent transparent transparent;
  }
  .ldsRing div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .ldsRing div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .ldsRing div:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.white {
  .ldsRing div {
    border: 8px solid #ffffff;
    border-color: #ffffff transparent transparent transparent;
  }
}
